<template>  
  <StandByHeader />
  <ThankYouContent />
</template>

<script>
  import StandByHeader from "../components/sections/StandByHeader.vue";
  import ThankYouContent from "../components/sections/ThankYouContent.vue";
  export default {
    data: function () {
      return {
        mode : process.env.NODE_ENV
      }
    },
    components: {
      StandByHeader,
      ThankYouContent
    }
  }
</script>
